import request from '../utils/request'

export function getMerchantInfo(merchant_id) {
    return request.post('./index.php', {
        function: 'getMerchantInfo',
        merchant_id
    })
}

export function getCollectionInfo(collection_id, merchant_id) {
    return request.post('./index.php', {
        function: 'getCollectionInfo',
        collection_id,
        merchant_id
    })
}

export function getAlbumInfo(album_id, merchant_id) {
    return request.post('./index.php', {
        function: 'getAlbumInfo',
        album_id,
        merchant_id
    })
}

export function addMerchantPurchase(params) {
    return request.post('./index.php', {
        function: 'addMerchantPurchase',
        mobile: params.mobile, //用户输入的手机号码
        kid_name: params.kid_name, //用户输入的孩子的姓名
        kid_primary_school_age: params.kid_primary_school_age, //小学起始年份
        merchant_id: params.merchant_id,
        purchase_type: params.purchase_type, //album或collection
        content_id: params.content_id, //album则是album_id，collection则是collection_id
        price: params.price, //消耗的可能点数
        gender: params.gender, //性别
        open_id: params.open_id
    })
}

export function addKenengPurchase(params) {
    return request.post('./index.php', {
        function: 'addKenengPurchase',
        mobile: params.mobile, //用户输入的手机号码
        kid_name: params.kid_name, //用户输入的孩子的姓名
        kid_primary_school_age: params.kid_primary_school_age, //小学起始年份
        merchant_id: params.merchant_id,
        purchase_type: params.purchase_type, //album或collection
        content_id: params.content_id, //album则是album_id，collection则是collection_id
        price: params.price, //消耗的可能点数
        gender: params.gender, //性别
        open_id: params.open_id
    })
}

export function getAliPayForm(purchase_id, subject, price, device_type) {
    return request.post('./index.php', {
        function: 'getAliPayForm',
        purchase_id,
        subject,
        price,
        device_type
    })
}

export function getOrderClause() {
    return request.post('./index.php', {
        function: 'getOrderClause'
    })
}

export function getFunctionIntroduction() {
    return request.post('./index.php', {
        function: 'getFunctionIntroduction'
    })
}

export function getUserPrivacyPolicy() {
    return request.post('./index.php', {
        function: 'getUserPrivacyPolicy'
    })
}

export function getUserAgreement() {
    return request.post('./index.php', {
        function: 'getUserAgreement'
    })
}

export function getOpenIdForFWH(code) {
    return request.post('./index.php', {
        function: 'getOpenIdForFWH',
        code
    })
}
export function getUserKenengPointByMobile(mobile) {
    return request.post('./index.php', {
        function: 'getUserKenengPointByMobile',
        mobile
    })
}



export function verifyLoginPin(mobile, pin, unionid, open_id) {
    return request.post('./index.php', {
        function: 'verifyLoginPin',
        mobile,
        pin,
        unionid,
        open_id
    })
}

export function startWXPayForFWH(params) {
    return request.post('./index.php', {
        function: 'startWXPayForFWH',
        purchase_id: params.purchase_id,
        price: params.price,
        open_id: params.open_id,
        subject: params.subject
    })
}

export function getPurchaseInfo(purchase_id) {
    return request.post('./index.php', {
        function: 'getPurchaseInfo',
        purchase_id
    })
}

export function getSignPackageFWH(url) {
    return request.post('./index.php', {
        function: 'getSignPackageFWH',
        url
    })
}

export function getUserOrderList(user_id) {
    return request.post('./index.php', {
        function: 'getUserOrderList',
        user_id
    })
}

export function createLoginPin(mobile) {
    return request.post('./index.php', {
        function: 'createLoginPin',
        mobile
    })
}

export function requestWXUserInfo(code) {
    return request.post('./index.php', {
        function: 'requestWXUserInfo',
        code
    })
}

export function getAlbumInfoV1(album_id) {
    return request.post('./index.php', {
        function: 'getAlbumInfoV1',
        album_id
    })
}

export function getSongInfoV1(song_id) {
    return request.post('./index.php', {
        function: 'getSongInfoV1',
        song_id
    })
}

export function getSongInfoV2(song_id, course_id) {
    return request.post('./index.php', {
        function: 'getSongInfoV2',
        song_id,
        course_id
    })
}

export function fwhApplyCourseApplication(params) {
    return request.post('./index.php', {
        function: 'fwhApplyCourseApplication',
        open_id: params.open_id,
        mobile: params.mobile,
        email: params.email,
        last_name: params.last_name,
        given_name: params.given_name
    })
}

export function getCourseInfo(course_id) {
    return request.post('./index.php', {
        function: 'getCourseInfo',
        course_id
    })
}

export function checkEnableInCourse(course_id) {
    return request.post('./index.php', {
        function: 'checkEnableInCourse',
        course_id
    })
}

export function submitCourseSongQuiz(params) {
    return request.post('./index.php', {
        function: 'submitCourseSongQuiz',
        quiz_id: params.quiz_id,
        song_id: params.song_id,
        course_id: params.course_id,
        answer: params.answer,
    })
}
export function notifyCourseQuizCompleted(course_id) {
    return request.post('./index.php', {
        function: 'notifyCourseQuizCompleted',
        course_id
    })
}
export function createSubscribeOrder(user_id) {
    return request.post('./index.php', {
        function: 'createSubscribeOrder',
        user_id
    })
}
export function startWXPayForCourseSubscribe(order_id) {
    return request.post('./index.php', {
        function: 'startWXPayForCourseSubscribe',
        order_id
    })
}
export function getUserAllCourses(user_id) {
    return request.post('./index.php', {
        function: 'getUserAllCourses',
        user_id
    })
}


export function loginById(user_id) {
    return request.post('./index.php', {
        function: 'loginById',
        user_id
    })
}