<template>
  <div style="width: 100%;height: 100vh;position: relative;overflow-y: auto;background-color: #F7F7F7">
    <img src="../../../assets/share_top.png" style="width: 100%;height: 56px;object-fit: fill">
    <div style="margin: 16px">
      <div class="pay-item flex-row">
        <div>
          <div v-if="purchase_type=='collection'">
            <img v-if="collectionInfo.cover_pic_url" :src="collectionInfo.cover_pic_url"
                 style="width: 96px;height: 96px;object-fit: cover">
            <div v-else-if="collectionInfo.album_contents && collectionInfo.album_contents.length>=4"
                 class="collectionImg">
              <img v-for="i in 4" :key="i" :src="collectionInfo.album_contents[i-1].cover_pic_url">
            </div>
            <img v-else src="../../../assets/default_collecton.png" style="width: 96px;height: 96px;object-fit: cover">
          </div>
          <div v-else>
            <img v-if="content_cover_pic" :src="content_cover_pic"
                 style="width: 96px;height: 96px;object-fit: cover">
            <img v-else src="../../../assets/defalut_album_v1.png"
                 style="width: 96px;height: 96px;object-fit: cover">
          </div>
        </div>
        <div style="margin-left: 20px">
          <p class="pay-title" style="font-size: 18px">{{ content_name }}</p>
          <div class="statistic" v-if="purchase_type==='collection'">
            <div class="item">共{{ collectionInfo.album_contents.length }}个专辑</div>
            <div class="item">共{{ collectionInfo.audio_length }}个音频</div>
            <div class="item">预估{{ Math.ceil(collectionInfo.audio_length / 5) }}个课时</div>
            <div class="item">预计学习{{ Math.ceil(collectionInfo.audio_length / 5 / 2) }}周</div>
          </div>
          <div class="statistic" v-else>
            <div class="item">共{{ albumInfo.album_songs.length }}个音频</div>
            <div class="item">预估{{ Math.ceil(albumInfo.album_songs.length / 5) }}个课时</div>
            <div class="item">预计学习{{ Math.ceil(albumInfo.album_songs.length / 5 / 2) }}周</div>
          </div>
          <p class="pay-price">￥<strong style="font-size: 28px">{{ price }}</strong></p>
        </div>

      </div>
      <div class="pay-item" style="margin-top: 10px">
        <p class="pay-title">订单条款</p>
        <div class="pay-empty-line" style="margin-top: 16px;margin-bottom: 10px"/>
        <p class="pay-second-title">
          <el-checkbox v-model="agree">
          </el-checkbox>
          同意<span class="tip" @click="showDialog=true">用户隐私与用户协议</span>
        </p>
      </div>
      <div class="pay-item" style="margin-top: 10px">
        <p class="pay-title">购买信息</p>
        <div class="pay-empty-line" style="margin-top: 16px;margin-bottom: 10px"/>
        <div class="pay-form">
          <p class="form-label">手机号</p>
          <el-input class="form-value" v-model="mobile" :disabled="user_mobile && user_mobile!=''"
                    placeholder="请填写家长手机号"></el-input>
        </div>
        <div v-if="merchant_id != ''" class="pay-empty-line"/>
        <div class="pay-form" v-if="merchant_id != ''">
          <p class="form-label">孩子姓名</p>
          <el-input class="form-value" v-model="kid_name" placeholder="请填写孩子姓名"></el-input>
        </div>
        <div class="pay-empty-line"/>
        <div class="pay-form">
          <p class="form-label">小学入学年份</p>
          <el-select v-model="kid_primary_school_age" class="form-value" placeholder="请填写小学入学年份">
            <el-option v-for="(year,index) in years" :key="index" :label="year" :value="year"></el-option>
          </el-select>
        </div>
        <div class="pay-empty-line"/>
        <div class="pay-form">
          <p class="form-label">孩子性别</p>
          <el-select v-model="gender" class="form-value" placeholder="请选择孩子性别">
            <el-option label="男" value="Male"></el-option>
            <el-option label="女" value="Female"></el-option>
          </el-select>
        </div>

        <div class="pay-empty-line"/>
        <div v-if="merchant_id != ''"
            style="margin-top: 15px;color: #666666;font-size: 15px;line-height: 22px;font-family: PingFang SC-Medium, PingFang SC">
          注：孩子信息将用于您孩子所在教育机构老师的教务管理，以及电子证书发放
        </div>
        <div v-if="merchant_id == ''"
            style="margin-top: 15px;color: #666666;font-size: 15px;line-height: 22px;font-family: PingFang SC-Medium, PingFang SC">
            注：孩子的入学年份和性别信息将用于更好的推荐相关内容课程。
        </div>

      </div>
      <p class="btn-purchase" @click="gotoPay">立即支付 ￥{{ price }}</p>
      <div style="width: 100%;height: 100px">
      </div>
    </div>
    <el-dialog :visible.sync="showDialog" width="90%">
      <el-tabs>
        <!--        <el-tab-pane label="功能介绍">-->
        <!--          <div v-html="intro"></div>-->
        <!--        </el-tab-pane>-->
        <el-tab-pane label="用户隐私">
          <div v-html="policy"></div>
        </el-tab-pane>
        <el-tab-pane label="用户协议">
          <div v-html="agreement"></div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>


    <el-dialog :visible.sync="showPay" center>
      <!-- <PayPal :amount="orderData.amount" :currency="orderData.currency_code" :client="credentials" env="sandbox"
        :button-style="buttonStyle" @payment-authorized="paymentAuthorized"
        @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">
      </PayPal> -->
      <!-- <span slot="header" class="dialog-footer"> -->

        <div style="align-items: center;text-align: center;">

          <span>选择支付方式</span>
        </div>

        <div style="align-items: center;text-align: center;margin-top: 30px;">
          <el-button  size="medium"  @click="kenengPay" type="primary">可能点支付</el-button>

          <el-button  style="margin-top:20px;" size="medium"  @click="weixinPay" type="primary">微信支付</el-button>
        </div>

        <div style="align-items: center;text-align: center;margin-top:20px">
          <span>剩余{{user_keneng_points}}可能点</span>
        </div>
        <div style="align-items: center;text-align: center;margin-top:20px">
          <span>注:一个是1可能点 = 1元人民币</span>
        </div>

        

      <!-- </span> -->

      <!-- <PayPal :amount="orderData.amount" :currency="orderData.currency_code" :client="credentials"
        :button-style="buttonStyle" @payment-authorized="paymentAuthorized"
        @payment-completed="paymentCompleted" @payment-cancelled="paymentCancelled">
      </PayPal> -->
       
    </el-dialog>


  </div>
</template>

<script>
import {
  addMerchantPurchase,
  getAlbumInfo,
  getCollectionInfo,
  getFunctionIntroduction,
  getSignPackageFWH,
  getUserAgreement,
  getUserPrivacyPolicy,
  startWXPayForFWH,
  addKenengPurchase,
  getUserKenengPointByMobile
} from '../../../api/keneng'
import '../../../assets/common/common.css'
import {getContentId, getMerchantId, getOpenId, getPurchaseType, getUserMobile, setUserId} from "../../../utils/store";

export default {
  name: "Pay",
  data() {
    return {
      user_mobile: getUserMobile(),
      open_id: getOpenId(),
      merchant_id: '',
      content_id: '',
      purchase_type: '',
      content_name: '',
      content_cover_pic: "",
      price: '',
      collectionInfo: {},
      albumInfo: {},
      kid_name: '',
      kid_primary_school_age: '',
      mobile: '',
      gender: '',
      agree: false,
      showDialog: false,
      intro: '',
      policy: '',
      agreement: '',
      years: [],
      showPay:false,
      user_keneng_points:0
    }
  },
  mounted() {
    this.merchant_id = getMerchantId()
    this.content_id = getContentId()
    this.purchase_type = getPurchaseType()

    this.mobile = getUserMobile()
    let currentYear = new Date().getFullYear()
    let start = currentYear - 5
    let end = currentYear + 5
    for (let i = start; i < end; i++) {
      this.years.push(i)
    }

    console.log('id1:' + this.merchant_id)
    console.log('id1:' + this.content_id)
    console.log('id1:' + this.purchase_type)

    if (this.open_id == undefined || this.open_id == '') {
      this.$message.error('OpenId为空')
      return
    }

    if (
        this.content_id == undefined ||
        this.content_id == '' ||
        this.purchase_type == undefined ||
        this.purchase_type == ''
    ) {
      this.$message.error('参数有误～')
      return
    }
    // if (this.merchant_id == undefined ||
    //     this.merchant_id == '' ||
    //     this.content_id == undefined ||
    //     this.content_id == '' ||
    //     this.purchase_type == undefined ||
    //     this.purchase_type == ''
    // ) {
    //   this.$message.error('参数有误～')
    //   return
    // }

    this.fetchData()
    getUserAgreement().then(res => {
      if (res.data.code === 0) {
        this.agreement = res.data.data.content
        this.agreement = this.agreement.replace('<input type=\"text\"', '<input type=hidden')
      }
    })
    getFunctionIntroduction().then(res => {
      if (res.data.code === 0) {
        this.intro = res.data.data.content
        this.intro = this.intro.replace('<input type=\"text\"', '<input type=hidden')
      }
    })
    getUserPrivacyPolicy().then(res => {
      if (res.data.code === 0) {
        this.policy = res.data.data.content
        this.policy = this.policy.replace('<input type=\"text\"', '<input type=hidden')
      }
    })

  },
  methods: {
    fetchData() {
      if (this.purchase_type == 'collection') {
        getCollectionInfo(this.content_id, this.merchant_id).then((res) => {
          let collectionInfo = res.data.data
          let audio_length = 0
          collectionInfo.album_contents.map(item => {
            audio_length += item.album_songs.length
          })
          collectionInfo.audio_length = audio_length
          this.collectionInfo = collectionInfo
          this.content_name = collectionInfo.collection_name
          this.content_cover_pic = collectionInfo.cover_pic_url
          document.title = '购买' + collectionInfo.collection_name

          if (this.merchant_id == ''){
          this.price = collectionInfo.collection_price
        }else{
          this.price = collectionInfo.mechant_content.price
        }

        })
      } else {
        getAlbumInfo(this.content_id, this.merchant_id).then((res) => {
          let albumInfo = res.data.data
          this.albumInfo = albumInfo
          this.content_name = albumInfo.album_name
          this.content_cover_pic = albumInfo.cover_pic_url
          document.title = '购买' + albumInfo.album_name
          this.price = albumInfo.mechant_content.price
        })
      }
    },
    gotoPay() {
      if (!this.agree) {
        this.$message.error('请勾选同意用户协议与隐私')
        return
      }

      console.log(1111,this.mobile)

      if (this.mobile == '' || this.mobile == undefined) {
        this.$message.error('请填写家长手机号')
        return
      }

      console.log(1111,this.mobile)

      if (this.merchant_id != ''){
        if (this.kid_name == '') {
          this.$message.error('请填写孩子姓名')
          return
        }
      }
      
      if (this.kid_primary_school_age == '') {
        this.$message.error('请填写入学年份')
        return
      }
      if (this.gender == '') {
        this.$message.error('请选择孩子性别')
        return
      }

      console.log(1111,this.mobile)


      getUserKenengPointByMobile(this.mobile).then((res) => {
        this.user_keneng_points = res.data.data
        this.showPay = true;
      })

      

      
    },

    kenengPay(){
      let params = {
        mobile: this.mobile,
        kid_name: this.kid_name,
        kid_primary_school_age: this.kid_primary_school_age,
        merchant_id: this.merchant_id,
        purchase_type: this.purchase_type,
        content_id: this.content_id,
        price: this.price,
        gender: this.gender,
        open_id: this.open_id
      }
      addKenengPurchase(params).then((res) => {
        let result = res.data

        this.showPay = false;

        if (result.code == 0) {


          this.$message({
            message: '缴费成功',
            type: 'success'
          })
          this.$router.replace({
            path: '/share-pay-success',
          })

        } else {
          this.$message.error(result.msg)
        }
      })
    },
    weixinPay(){


      if (!this.agree) {
        this.$message.error('请勾选同意用户协议与隐私')
        return
      }
      if (this.mobile == '') {
        this.$message.error('请填写家长手机号')
        return
      }
      if (this.merchant_id != ''){

        if (this.kid_name == '') {
          this.$message.error('请填写孩子姓名')
          return
        }
      }
      
      if (this.kid_primary_school_age == '') {
        this.$message.error('请填写入学年份')
        return
      }
      if (this.gender == '') {
        this.$message.error('请选择孩子性别')
        return
      }

      let params = {
        mobile: this.mobile,
        kid_name: this.kid_name,
        kid_primary_school_age: this.kid_primary_school_age,
        merchant_id: this.merchant_id,
        purchase_type: this.purchase_type,
        content_id: this.content_id,
        price: this.price,
        gender: this.gender,
        open_id: this.open_id
      }
      addMerchantPurchase(params).then((res) => {
        let result = res.data

        this.showPay = false;

        if (result.code == 0) {
          setUserId(result.data.user_id)
          this.goWrapPay(result.data._id.$id)
        } else {
          this.$message.success('已经购买过此内容了，无需在进行购买')
        }
      })
    },

    goWrapPay(purchase_id) {
      let params = {
        purchase_id: purchase_id,
        price: this.price,
        open_id: this.open_id,
        subject: this.content_name
      }
      startWXPayForFWH(params).then((res) => {
        let result = res.data
        if (result.code == 0) {
          this.showWXPay(result.data.timeStamp, result.data.nonceStr, result.data.package, result.data.signType, result.data.paySign)
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    showWXPay(timestamp, nonceStr, package1, signType, paySign) {
      let that = this
      let url = window.location.href//this.$route.fullPath
      getSignPackageFWH(url).then((res) => {
        const result = res.data
        wx.config({
          debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
          appId: result.appId, // 必填，公众号的唯一标识
          timestamp: result.timestamp, // 必填，生成签名的时间戳
          nonceStr: result.nonceStr, // 必填，生成签名的随机串
          signature: result.signature, // 必填，签名
          jsApiList: [
            'chooseWXPay'
          ] // 必填，需要使用的JS接口列表
        })
      })
      wx.ready(() => {
        wx.chooseWXPay({
          timestamp: timestamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
          nonceStr: nonceStr, // 支付签名随机串，不长于 32 位
          package: package1, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: paySign, // 支付签名
          success: (res) => {
            // 支付成功后的回调函数
            that.$message({
              message: '缴费成功',
              type: 'success'
            })
            that.$router.replace({
              path: '/share-pay-success',
            })
          }
        })
      })
      wx.error((err) => {
        console.log(err)
      })
    }
  }
}
</script>

<style scoped lang="less">

.btn-purchase {
  cursor: pointer;
  margin-top: 60px;
  text-align: center;
  width: 100%;
  height: 40px;
  line-height: 40px;
  background: #FF5E45;
  border-radius: 24px 24px 24px 24px;
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
}

.pay-empty-line {
  width: 100%;
  height: 1px;
  background-color: #F7F7F7;
}

.pay-price {
  margin-top: 10px;
  color: #FF5E45;
  font-size: 14px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
}

.pay-item {
  background: #FFFFFF;
  border-radius: 12px 12px 12px 12px;
  padding: 12px 16px;
}

.pay-title {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}

.statistic {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .item {
    border-radius: 4px;
    border: 1px solid #5D5BB5;
    font-size: 12px;
    font-family: 'Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20';
    font-weight: normal;
    color: #5D5BB5;
    padding: 4px 8px;
    box-sizing: border-box;
    margin-right: 10px;
    margin-top: 8px;
  }
}

.collectionImg {
  display: flex;
  flex-wrap: wrap;
  width: 96px;
  height: 96px;
  flex-shrink: 0;

  img {
    width: 50%;
    height: 50%;
  }

  img:nth-child(1) {
    border-radius: 12px 0 0 0;
  }

  img:nth-child(2) {
    border-radius: 0 12px 0 0;
  }

  img:nth-child(3) {
    border-radius: 0 0 0 12px;
  }

  img:nth-child(4) {
    border-radius: 0 0 12px 0;
  }

}

.pay-second-title {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 28px;
}

.pay-form {
  width: 100%;
  height: 53px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.form-value {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #CCCCCC;
  outline: none;
  border: none;
}

.form-label {
  width: 30%;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
  line-height: 18px;
}

.tip {
  color: #409EFF;
}
</style>
